body {
  background: #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: 'Roboto';
}

.App {
  height: 400px;
  width: 300px;
  perspective: 0 200px;
  background: #EBEBEB;
  transform: scale(1.5) skew(0deg);
  transition: transform .6s;
  position: relative;
}

.App:hover::before {
  opacity: 0;
  height: 0;
}

.App:hover::after {
  opacity: 0;
  width: 0;
}

.App::before {
  position: absolute;
  content: '';
  background: #bdc7b7;
  width: 100%;
  height: 10px;
  top: 100%;
  left: 10px;
  transform: skewX(45deg);
  opacity: 1;
  transform-origin: 0 100%;
  perspective: 0 200px;
  transition: transform .5s ease .5s, height .5s ease .5s, opacity .5s ease .5s;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 1px;
}

.Display {
  background: #212025;
  color: #59C3C3;
  text-shadow: 0 0 5px rgba(89, 195, 195, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 29px;
  box-sizing: border-box;
  height: 20%;
  overflow: hidden;
  font-size: 24px;
  position: relative;
}

.Buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 80%;
}

.Button {
  background: #cad2c5;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #cad2c5 0%, #abb5aa 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #cad2c5 0%, #abb5aa 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #cad2c5 0%, #abb5aa 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cad2c5', endColorstr='#abb5aa', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  display: flex;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: center;
  width: 25%;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
  height: 20%;
  min-width: 25%;
}

.Button[data-size="2"] {
  height: 40%;
}

.Button[data-value="null"] {
  pointer-events: none;
}

.Button:hover {
  background: #d7ddd3;
  cursor: pointer;
}

.Button:active {
  background: #bdc7b7;
}
